import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/contratos-eps';

export default {
    search(search = '', pagination = {}) {

        let url = `${resource}/search?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);

    },
    async index(search = '',pagination = {}) {
        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    async get(id) {
        let url = `${resource}/show/${id}`;
        return Service.get(url);
    },
    async update(contrato) {
        let url = `${resource}/update`;
        return Service.put(url, contrato);
    },
    async save(contrato) {
        let url = `${resource}/store`;
        return Service.post(url, contrato);
    },
    async getTiposAfiliacion() {
        let url = `/api/afiliacion/get`;
        return Service.get(url);
    }
}
